
import { Component, Mixins } from 'vue-property-decorator';
import RatePageOtelLogs from '@/modules/open-telemetry/mixins/rates/rates-page-otel-logs.mixin';
import RatesTable from '../components/table/rates-table.vue';
import TablePageLayout from '../components/table-page-layout.vue';

@Component({
    components: {
        RatesTable,
        TablePageLayout,
    },
})
export default class RatesTablePage extends Mixins(RatePageOtelLogs) {}
